<template>
  <v-card v-if="!loading">
    <v-card-text class="grey lighten-3">
      <v-row>
        <v-col cols="12" sm="3">
          <v-currency-field
            :label="$t('Chef.minimum_order_price')"
            v-bind="currency_config"
            :error-messages="errors.positive"
            v-model="minimumOrderAmount"
            :rules="[rules.positive]"
          ></v-currency-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="savePriceSettings" color="primary">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import currency from 'v-currency-field'
Vue.use(currency)
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      minimumOrderAmount: 0,

      errors: {},
      currency_config: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: 0.0,
        max: Number.MAX_SAFE_INTEGER
      },
      rules: {
        amountRule: value =>
          !value ||
          this.all.totalServing >= parseInt(value) ||
          'Amount left must me smaller or same as total serving.',
        positive: value => value >= 0 || 'Should be greater than zero.'
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile'
    })
  },
  mounted() {
    if (this.profile && this.profile.settings) {
      this.minimumOrderAmount = this.profile.settings.minimumOrderAmount
    }
    //, this.maxDeliveryRange,this.deliveryCharge } = this.settings
  },
  methods: {
    savePriceSettings() {
      this.$store.dispatch('saveSettings', {
        minimumOrderAmount: this.minimumOrderAmount
      })
    }
  }
}
</script>
