<template>
  <div>
    <template v-if="false">
      <cite
        >TakeIn allows restaurants to choose their commission structure. If they
        chose 15% for the restaurant the customer will be charged a 0% service
        fee. If the restaurant chooses 10%, the customer will be charged 5%. The
        lower the customer fee the more likely they are to buy. For reference
        other delivery apps charge customers between 5%-15% plus 30% to the
        restaurant. TakeIn needs 15% in total to cover its operating cost. We
        put restaurants in control of how they market to their customers.</cite
      >

      <v-sheet class="v-sheet--offset mt-5" max-width="calc(100% - 32px)">
        <v-sparkline
          :labels="sliderLabel"
          :value="feeCalc"
          color="info"
          show-labels
          line-width="20"
          type="bar"
          :gradient="['#1565C0', '#42b3f4']"
          label-size="5"
          padding="16"
        ></v-sparkline>
      </v-sheet>
      <v-slider
        v-model="feeDistribution"
        label="Fee Distribution"
        thumb-label="always"
        min="0"
        max="100"
        step="10"
      >
        <template v-slot:thumb-label="{ value }">{{ value }}%</template>
      </v-slider>
    </template>

    <v-row v-if="!loading && profile">
      <v-col cols="12" sm="6" md="4" v-if="!pickupOnly">
        <v-switch
          label="I can deliver my products"
          v-model="canDeliver"
          @change="changeDeliverState"
        ></v-switch>
        <v-container v-if="canDeliver">
          <v-row>
            <v-col cols="12" sm="4" class="mr-3">
              <v-text-field
                label="Extra Charge"
                hint="This is will be added to total price"
                v-model="deliveryCharge"
                :disabled="hasDeliveryRange"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="Maximum Delivery range"
                v-model="maxDeliveryRange"
                type="number"
                :disabled="hasDeliveryRange"
                suffix="Miles"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-alert :value="deliveryError" type="error">{{
            deliveryErrorMessage
          }}</v-alert>
          <v-row
            v-for="(item, key) in deliveryRangePrices"
            :key="key"
            align="center"
          >
            <v-col lg="2" md="3" sm="4">
              <v-text-field
                v-model="item.from"
                label="from"
                suffix="miles"
                type="number"
                :min="0"
              ></v-text-field>
            </v-col>
            <v-col lg="2" md="3" sm="4">
              <v-text-field
                v-model="item.to"
                label="to"
                suffix="miles"
                type="number"
                :min="parseInt(item.from) + 1"
              ></v-text-field>
            </v-col>
            <v-col md="2" sm="3">
              <v-text-field
                v-model="item.amount"
                label="Delivery Cost"
                prefix="$"
                type="number"
                min="0"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn icon @click="removeItem(key)">
                <v-icon color="red">delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-btn fab @click="addItem">
              <v-icon small>add</v-icon>
            </v-btn>
          </v-row> -->
        </v-container>
        <v-row v-if="!canDeliver">
          <v-col cols="12" sm="6" v-if="false">
            <v-switch
              label="I will control when to call delivery driver"
              v-model="arrangeThirdPartyDelivery"
            ></v-switch>
            <v-alert
              type="success"
              outlined
              border="top"
              dense
              v-if="arrangeThirdPartyDelivery"
            >
              {{ settings.arrangeThirdPartyDelivery }}
              <small>
                I acknowledge I am responsible to call delivery driver when
                product is ready
              </small>
            </v-alert>
          </v-col>
        </v-row>
        <v-switch
          label="I allow pickup from my address"
          v-model="canPickup"
          @change="changePickupState"
        ></v-switch>
        <template v-if="canPickup">
          <v-row class="mb-3 pl-3">
            <v-col cols="12" sm="8">
              <v-icon>alert</v-icon>
              <!-- Causes memory leak, need to reconsider -->
              <!-- <formatted-address :location="pickupAddress" /> -->
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col cols="8">
              <v-switch
                v-model="useBusinessHours"
                label="Use Earliest and Latest Business Hours"
                @change="setToBusinessHours()"
                :disabled="!profile.businessHours"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row v-if="!useBusinessHours">
            <v-col cols="12" sm="6">
              <v-row>
                <v-col cols="12">
                  <strong>Earliest Pickup time</strong>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <v-time-picker
                    v-model="pickupTimeEarliest"
                    width="200"
                    landscape
                    :ampm-in-title="true"
                    class="mt-3"
                    @input="checkForBusinessHour()"
                  ></v-time-picker>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row>
                <v-col cols="12">
                  <strong>Latest Pickup time</strong>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <v-time-picker
                    v-model="pickupTimeLatest"
                    width="200"
                    landscape
                    :ampm-in-title="true"
                    class="mt-3"
                    @input="checkForBusinessHour()"
                  ></v-time-picker>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>

        <v-row>
          <v-col>
            <v-alert
              v-if="!canDeliver && !canPickup && !allowThirdPartyDelivery"
              :value="true"
              type="error"
              >One of the two options above MUST be turned on.</v-alert
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="6" md="4" v-if="!canDeliver && !pickupOnly">
        <v-switch
          label="Allow Third Party Delivery"
          v-model="allowThirdPartyDelivery"
          @change="changeDeliverState"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="canPickup">
        <v-switch
          label="Pickup Only"
          v-model="pickupOnly"
          @change="changePickupState"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="!pickupOnly">
      <v-col cols="12" md="6">
        <v-textarea
          rows="2"
          v-model="driverNotes"
          counter="50"
          label="Driver Notes"
          class="body-1"
          placeholder="Send pickup or delivery notes to the delivers. For example skip the line and use backdoor."
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6">
        <v-switch
          label="Send personal message after delivery"
          v-model="sendDeliverMessage"
        ></v-switch>
        <v-card v-if="sendDeliverMessage" elevation="1" width="100%">
          <v-card-title class="pa-0 ma-0"
            ><v-spacer></v-spacer>
            <v-icon small color="blue" @click="copyDeliveryMessage">
              mdi-message</v-icon
            ></v-card-title
          >
          <v-card-text>
            <v-textarea
              rows="4"
              v-model="postDeliveryMessage"
              label="Message"
              class="body-1"
              placeholder="Your persoanl message"
            ></v-textarea>
            <v-text-field
              rows="4"
              v-model="postDeliveryMediaLink"
              label="Media Link"
              class="body-1"
              placeholder="Link to a photo or video"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn small @click="saveDeliveryMessage">Save Message</v-btn>
          </v-card-actions>
        </v-card>

        <v-dialog v-model="noValidInfo" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title
              >Missing Information</v-card-title
            >

            <v-card-text
              >Please make sure you have a valid address and
              number.</v-card-text
            >

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                @click="
                  ;(noValidInfo = false), (allowThirdPartyDelivery = false)
                "
                >Okay</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="my-2">
      <v-col>
        <v-btn
          :disabled="!canDeliver && !canPickup && !allowThirdPartyDelivery"
          @click="saveDeliverySettings"
          color="primary"
          >Save</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormattedAddress from '@/components/common/FormattedAddress'
import moment from 'moment'
export default {
  components: {
    FormattedAddress
  },
  data() {
    return {
      canDeliver: false,
      allowThirdPartyDelivery: true,
      arrangeThirdPartyDelivery: false,
      canPickup: true,
      pickupOnly: false,
      sendDeliverMessage: false,
      postDeliveryMessage: null,
      postDeliveryMediaLink: null,
      pickupTimeEarliest: '09:00',
      pickupTimeLatest: '08:00',
      maxDeliveryRange: 10,
      deliveryCharge: 5,
      noValidInfo: false,
      useBusinessHours: true,
      deliveryRangePrices: [],
      feeDistribution: 50,
      deliveryError: false,
      deliveryErrorMessage: '',
      driverNotes: null
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      settings: 'userSettings'
    }),
    pickupAddress() {
      let pickup_address = this.profileAddress(this.profile, 'pickup')
      return pickup_address ? pickup_address : this.profileDefaultAddress
    },
    feeCalc() {
      return [this.feeDistribution, this.feeDistribution - 100]
    },
    sliderLabel() {
      return [
        `I pay ${this.feeDistribution}%`,
        `Cusomter pays ${100 - this.feeDistribution}%`
      ]
    },
    hasDeliveryRange() {
      return this.deliveryRangePrices && this.deliveryRangePrices.length > 0
    }
  },
  mounted() {
    if (this.settings) {
      this.allowThirdPartyDelivery =
        this.settings.allowThirdPartyDelivery === undefined ||
        this.settings.allowThirdPartyDelivery === null
          ? true
          : this.settings.allowThirdPartyDelivery
      this.arrangeThirdPartyDelivery = !!this.settings.arrangeThirdPartyDelivery
      this.canDeliver = this.settings.canDeliver
      this.maxDeliveryRange = this.settings.maxDeliveryRange
      this.deliveryCharge = this.settings.deliveryCharge
      this.pickupOnly = this.settings.pickupOnly
      this.canPickup =
        this.settings.canPickup === undefined ||
        this.settings.canPickup === null
          ? true
          : this.settings.canPickup
      this.pickupTimeEarliest = this.settings.pickupTimeEarliest
        ? this.settings.pickupTimeEarliest
        : this.pickupTimeEarliest
      this.pickupTimeLatest = this.settings.pickupTimeLatest
        ? this.settings.pickupTimeLatest
        : this.pickupTimeEarliest
      this.useBusinessHours =
        this.settings.useBusinessHours === undefined ||
        this.settings.useBusinessHours === null
          ? true
          : this.settings.useBusinessHours
      this.deliveryRangePrices = this.settings.deliveryRangePrices || []
      this.sendDeliverMessage = this.settings.sendDeliverMessage
      this.postDeliveryMessage = this.settings.postDeliveryMessage
      this.postDeliveryMediaLink = this.settings.postDeliveryMediaLink
      this.driverNotes = this.settings.driverNotes
    } else {
      console.log('Setting is empty!')
      //this.$store.dispatch('loadProfile')
    }
  },
  methods: {
    addItem() {
      let from = 0,
        to = 1
      if (this.deliveryRangePrices && this.deliveryRangePrices.length > 0) {
        from =
          this.deliveryRangePrices[this.deliveryRangePrices.length - 1].to + 1
        to = from + 1
      }
      this.deliveryRangePrices.push({ from: from, to: to, amount: 0.0 })
    },
    removeItem(key) {
      this.deliveryRangePrices = this.deliveryRangePrices.filter(
        (_, i) => i !== key
      )
    },
    changePickupState() {
      this.$store.dispatch(
        'changeDeliverState',
        this.canPickup || this.canDeliver ? true : false
      )
    },
    validAddressNumber() {
      if (this.profile.phone && this.pickupAddress) {
        return true
      } else {
        return false
      }
    },
    changeDeliverState() {
      if (this.allowThirdPartyDelivery && !this.validAddressNumber()) {
        this.noValidInfo = true
      } else {
        this.$store.dispatch(
          'changeDeliverState',
          this.canPickup || this.canDeliver ? true : false
        )
      }
    },
    setToBusinessHours() {
      if (this.useBusinessHours && this.profile.businessHours) {
        let earliest, latest
        this.profile.businessHours.forEach(day => {
          if (earliest) {
            if (
              moment(day.fromHour, 'hh:mm a').isBefore(
                moment(earliest, 'hh:mm a')
              )
            )
              earliest = day.fromHour
          } else {
            earliest = day.fromHour
          }
          if (latest) {
            if (
              moment(latest, 'hh:mm a').isBefore(moment(day.endHour, 'hh:mm a'))
            )
              latest = day.endHour
          } else {
            latest = day.endHour
          }
          earliest = moment(earliest, ['h:mm a']).format('HH:mm')
          latest = moment(latest, ['h:mm a']).format('HH:mm')
          this.pickupTimeEarliest = earliest
          this.pickupTimeLatest = latest
        })
      }
    },
    checkForBusinessHour() {
      if (this.useBusinessHours) {
        this.useBusinessHours = false
      }
    },
    loadDeliverySettings() {
      // this.$store
      //   .dispatch('getUserSetting', { type: 'delivery_settings' })
      //   .then(res => {
      //     if (this.settings) {
      //       this.allowThirdPartyDelivery =
      //         this.settings.allowThirdPartyDelivery || true
      //       this.arrangeThirdPartyDelivery =
      //         this.settings.allowThirdPartyDelivery || false
      //       this.canDeliver = this.settings.canDeliver
      //       this.maxDeliveryRange = this.settings.maxDeliveryRange
      //       this.deliveryCharge = this.settings.deliveryCharge
      //       this.canPickup = this.settings.canPickup
      //       this.pickupTimeEarliest = this.settings.pickupTimeEarliest
      //         ? this.settings.pickupTimeEarliest
      //         : this.pickupTimeEarliest
      //       this.pickupTimeLatest = this.settings.pickupTimeLatest
      //         ? this.settings.pickupTimeLatest
      //         : this.pickupTimeEarliest
      //       this.useBusinessHours = this.settings.useBusinessHours
      //     } else {
      //       console.log('Setting is empty!')
      //       //this.$store.dispatch('loadProfile')
      //     }
      //   })
    },
    saveDeliverySettings() {
      if (
        this.canDeliver &&
        this.deliveryRangePrices.length > 0 &&
        !this.rangeIsCorrect()
      ) {
        return
      }

      this.$store
        .dispatch('saveSettings', {
          type: 'delivery_settings',
          allowThirdPartyDelivery: this.allowThirdPartyDelivery,
          arrangeThirdPartyDelivery: this.arrangeThirdPartyDelivery || null,
          canDeliver: this.canDeliver || false,
          maxDeliveryRange: this.maxDeliveryRange || null,
          deliveryCharge: this.deliveryCharge || null,
          canPickup: this.canPickup,
          pickupOnly: this.pickupOnly,
          pickupTimeEarliest: this.pickupTimeEarliest || null,
          pickupTimeLatest: this.pickupTimeLatest || null,
          useBusinessHours: this.canPickup ? this.useBusinessHours : false,
          deliveryRangePrices: this.deliveryRangePrices || null,
          driverNotes: this.driverNotes
        })
        .then(() => {
          this.$store.dispatch('getUserSettings')
          this.$store.dispatch('setMessage', {
            title: 'Saved',
            body: ''
          })
        })
    },
    rangeIsCorrect() {
      // need to check max and min miles and prices
      this.deliveryRangePrices.forEach(item => {
        if (item.to < item.from) {
          this.deliveryError = true
          this.deliveryErrorMessage = 'Mile Range does not Makes Sense.'
          return false
        }
        if (item.amount < 0) {
          this.deliveryError = true
          this.deliveryErrorMessage = 'Amount Cannot be Less than 0.'
          return false
        }
      })
      return true
    },
    milesChanged(item) {
      if (item.from && item.to && item.to < item.from) {
        this.deliveryError = true
        this.deliveryErrorMessage = 'Delivery Range is Not Possible'
      }
    },
    copyDeliveryMessage() {
      this.postDeliveryMessage = `Hello 
This message is from ${this.profile.businessName}

I would like to say thank you for ordering from us. 
Enjoy your food. Looking forward to see you again.
      `
    },
    saveDeliveryMessage() {
      this.$store
        .dispatch('saveSettings', {
          type: 'delivery_settings',
          sendDeliverMessage: this.sendDeliverMessage,
          postDeliveryMessage: this.postDeliveryMessage,
          postDeliveryMediaLink: this.postDeliveryMediaLink
        })
        .then(() => {
          this.$store.dispatch('getUserSettings')
          this.$store.dispatch('setMessage', {
            title: 'Saved',
            body: ''
          })
        })
    }
  }
}
</script>
