<template>
  <div v-if="!loading && profile && profile.settings">
    <v-row>
      <cite
        >Unlike other delivery apps that charge restaurants 30%+ and consumers
        15% at checkout TakeIn’s entire fee structure is 15% and checkout.
        Further, tips go to you not our drivers. If you choose 15% commission
        that means 0% service fee for customers. If you choose 0% commissions it
        means your customers will be charged a 15% service which is in line with
        other apps. With TakeIn you TakeControl and choose the fee structure
        that works for you and your customers.
      </cite>
    </v-row>

    <v-row v-if="serviceFees">
      <v-col cols="12" md="6" lg="5" v-if="producerPickupPct >= 0">
        <service-fee
          :serviceType="`Service Fee for Pickups is ${serviceFees.pickupPct}%`"
          :maxValue="serviceFees.pickupPct"
          :current="producerPickupPct"
          @changeValue="changePickupServiceFee"
        />
      </v-col>
      <v-col class="hidden-md-and-down" lg="2"> </v-col>
      <v-col cols="12" md="6" lg="5" v-if="producerDeliveryPct >= 0">
        <service-fee
          :serviceType="
            `Service Fee for Delivery is ${serviceFees.deliveryPct}%`
          "
          :maxValue="serviceFees.deliveryPct"
          :current="producerDeliveryPct"
          @changeValue="changeDeliveryServiceFee"
        />
      </v-col>
    </v-row>

    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="5" sm="4" md="3" lg="2">
            <v-currency-field
              :label="$t('Chef.minimum_order_price')"
              v-bind="currency_config"
              :error-messages="errors.positive"
              v-model="minimumOrderAmount"
              :rules="[rules.positive]"
            ></v-currency-field>
          </v-col>
          <v-col cols="1" sm="2"></v-col>
          <v-col cols="5" sm="4" md="3" lg="2">
            <v-text-field
              :label="$t('Chef.default_lead_time')"
              v-model="defaultLeadTime"
              :rules="[rules.positive]"
              suffix="minutes"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="8" sm="4" md="3">
            <v-switch
              :label="$t('Chef.call_robot')"
              v-model="robotCall"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="8" v-if="robotCall">
            <p>{{ $t('Chef.call_robot_info') }}</p>

            <v-alert type="info" v-if="profile.businessPhone" dense outlined>
              Notification call will go to your business phone
              {{ profile.businessPhone }}
            </v-alert>
            <v-alert type="error" dense outlined v-else>
              Your business phone empty, please your
              <router-link to="/my/profile">profile</router-link>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="4">
            <v-switch
              label="Disable Pre-Order"
              v-model="disable_preorder"
            ></v-switch>
            <i class="small" v-if="disable_preorder">Customers Can Only Place Orders During Business Hours</i>
          </v-col>
          <v-col cols="6" md="4">
            <v-switch label="Disable Tip Option" v-model="noTip"></v-switch>
          </v-col>

          <v-col cols="6" md="4">
            <v-switch
              :prepend-icon="disable_order_sound ? 'mdi-bell-off' : 'mdi-bell'"
              label="Disable Order Sound Notification"
              v-model="disable_order_sound"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" sm="4" md="3" lg="2"
            ><v-text-field
              v-model="tax_rate"
              label="Tax rate"
              placeholder="8.5%"
              suffix="%"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="savePriceSettings" color="primary">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue'
import currency from 'v-currency-field'
import ServiceFee from './ServiceFee'
Vue.use(currency)
import { mapGetters } from 'vuex'

export default {
  components: {
    ServiceFee
  },
  data() {
    return {
      minimumOrderAmount: 0,
      defaultLeadTime: 10,
      robotCall: false,
      disable_preorder: false,
      disable_order_sound: false,
      noTip: false,
      producerDeliveryPct: 0,
      producerPickupPct: 0,
      tax_rate: 0.08,
      errors: {},
      currency_config: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: 0.0,
        max: Number.MAX_SAFE_INTEGER
      },
      rules: {
        amountRule: value =>
          !value ||
          this.all.totalServing >= parseInt(value) ||
          'Amount left must me smaller or same as total serving.',
        positive: value => value >= 0 || 'Should be greater than zero.'
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      serviceFees: 'serviceFees',
      profile: 'profile'
    })
  },
  async mounted() {
    if (this.profile && this.profile.settings) {
      const fees = await this.$store.dispatch('getOrderFees')
      console.log('All fee settings: ', fees)
      this.tax_rate = this.profile.settings.tax_rate
        ? Math.round(
            (this.profile.settings.tax_rate * 100 + Number.EPSILON) * 100
          ) / 100
        : null
      this.minimumOrderAmount = this.profile.settings.minimumOrderAmount
      this.defaultLeadTime = this.profile.settings.defaultLeadTime
        ? this.profile.settings.defaultLeadTime
        : this.defaultLeadTime
      this.robotCall = this.profile.settings.robotCall

      this.disable_preorder = !(
        this.profile.settings.allow_preorder ||
        this.profile.settings.allow_preorder === undefined
      )
      this.disable_order_sound = !!this.profile.settings.disable_order_sound
      this.noTip = !!this.profile.settings.noTip

      this.producerDeliveryPct =
        this.profile.settings.producerDeliveryPct == null
          ? fees.producerDeliveryPct
          : this.profile.settings.producerDeliveryPct
      this.producerPickupPct =
        this.profile.settings.producerPickupPct == null
          ? fees.producerPickupPct
          : this.profile.settings.producerPickupPct
    }
    //, this.maxDeliveryRange,this.deliveryCharge } = this.settings
  },
  methods: {
    savePriceSettings() {
      this.$store
        .dispatch('saveSettings', {
          minimumOrderAmount: this.minimumOrderAmount,
          robotCall: this.robotCall || null,
          defaultLeadTime: parseInt(this.defaultLeadTime),
          allow_preorder: !this.disable_preorder,
          disable_order_sound: this.disable_order_sound,
          noTip: this.noTip,
          producerPickupPct: this.producerPickupPct,
          producerDeliveryPct: this.producerDeliveryPct,
          tax_rate: this.tax_rate ? this.tax_rate / 100 : null
        })
        .then(() => this.$store.dispatch('getOrderFees'))
      this.$store.dispatch('setMessage', {
        title: 'Saved',
        body: ''
      })
    },
    changePickupServiceFee(value) {
      console.log(`Pickup Service fee changed:${value}`)

      this.producerPickupPct = value
    },
    changeDeliveryServiceFee(value) {
      console.log(`Delivery Service fee changed:${value}`)
      this.producerDeliveryPct = value
    }
  }
}
</script>
