<template>
  <v-container :fluid="$vuetify.breakpoint.md" v-if="!loading">
    <v-row>
      <v-col cols="12">
        <approval-message />
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t('Chef.setting_order') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <order-setup />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t('Chef.setting_delivery') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <delivery-setup />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t('Chef.setting_delivery_subsidy') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <delivery-subsidy />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="false">
            <v-expansion-panel-header>Foods</v-expansion-panel-header>
            <v-expansion-panel-content>
              <food-setup />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="false">
            <v-expansion-panel-header>Notifications</v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>Coming soon</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <pagination-alert
      ref="paginationAlert"
      heading="Warning"
      message="Are you sure you want to leave this page without saving contents?"
    ></pagination-alert>
  </v-container>
</template>

<script>
import DeliverySetup from './DeliverySetup'
import DeliverySubsidy from './DeliverySubsidy'
import FoodSetup from './FoodSetup'
import PriceSetup from './PriceSetup'
import OrderSetup from './OrderSetup'
import ApprovalMessage from '../chef//profile/ApprovalMessage'

import { mapGetters } from 'vuex'

/*
Don’t send me anything (aside from vital account emails such as password reset notifications)
*/
export default {
  components: {
    DeliverySubsidy,
    DeliverySetup,
    FoodSetup,
    PriceSetup,
    OrderSetup,
    ApprovalMessage,
    PaginationAlert: () =>
      import('fod-core/src/components/common/PaginationAlert.vue')
  },
  computed: {
    ...mapGetters({
      user: 'user',
      loading: 'loading'
    })
  },
  mounted() {
    this.$store.dispatch('getUserSettings').then(() => {})
  }
  // beforeRouteLeave(to, from, next) {
  // this.$refs.paginationAlert.openDialog()
  // this.$nextTick(() => {
  //   this.$refs.paginationAlert.$once('onYes', () => {
  //     next(true)
  //   })
  //   this.$refs.paginationAlert.$once('onCancel', () => {
  //     next(false)
  //   })
  // })
  // }
}
</script>
