<template>
  <div id="app" v-if="current >= 0">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8"
        ><span class="title">{{ serviceType }} </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <VueSvgGauge
          class="customizable-gauge"
          :start-angle="parseInt(startAngle || 90)"
          :end-angle="parseInt(endAngle || -90)"
          :value="parseInt(current * 2)"
          :separator-step="parseInt(separatorStep)"
          :min="parseInt(min || 0)"
          :max="parseInt(max || 100)"
          :scale-interval="parseInt(scale)"
          :inner-radius="parseInt(innerRadius)"
          :separator-thickness="parseInt(separatorThickness)"
          :base-color="baseColor"
          :gauge-color="[
            { offset: 0, color: '#64bf8a' },
            { offset: 50, color: 'blue' },
            { offset: 100, color: 'black' }
          ]"
          :easing="easing"
        >
        </VueSvgGauge>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="overline"> {{ slider }}% Restaurant</v-col>

      <v-col cols="8">
        <v-slider
          v-model="slider"
          thumb-label="always"
          min="0"
          :max="maxValue"
          step=".5"
          @change="changeValue"
        >
          <template v-slot:thumb-label="{ value }"> {{ value }}% </template>
        </v-slider>
      </v-col>
      <v-col cols="2" class="overline">{{ maxValue - slider }}% Customer</v-col>
    </v-row>
  </div>
</template>

<script>
import { VueSvgGauge } from 'vue-svg-gauge'

export default {
  name: 'ServiceFee',
  props: {
    maxValue: {
      type: Number,
      required: true
    },
    serviceType: {
      type: String,
      required: true
    },
    current: {
      type: Number
    }
  },
  components: {
    VueSvgGauge
  },
  data() {
    return {
      startAngle: -90,
      endAngle: 90,
      value: 50,
      slider: 0,
      separatorStep: 50,
      min: 0,
      max: 100,
      scale: 1,
      innerRadius: 60,
      separatorThickness: 1,
      baseColor: '#d0cdcd',
      easingFct: 'Circular',
      easingType: 'Out',
      random: 30
    }
  },
  computed: {
    easing() {
      const { easingFct, easingType } = this
      return [easingFct, easingType].join('.')
    }
  },
  mounted() {
    this.value = this.current * 2
    this.slider = this.current
    this.max = this.maxValue * 2
  },

  watch: {
    current() {
      this.value = this.current * 2
      this.slider = this.current
    },
    slider() {
      this.value = this.slider * 2
    }
  },
  methods: {
    changeValue() {
      this.$emit('changeValue', this.slider)
    }
  }
}
</script>
