<template>
  <v-card v-if="!loading">
    <!-- show sub ingredient list only if chef is cfo -->
    <v-card-text class="grey lighten-3">
      Sub Ingredient List
      <v-row justify="center">
        <v-col v-if="isCFO(profile)" cols="12" sm="8">
          <v-data-table
            :items="subIngredientList"
            :headers="headers"
            item-key="name"
            class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="(item, index) in items"
                  :key="`subIngredientList-${index}`"
                >
                  <td>{{ item[0] | capitalize }}</td>
                  <td>{{ toList(item[1]) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>

        <v-col v-else cols="12" sm="8">
          <span class="grey--text">Coming Soon!</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { profileMixin } from 'fod-core'
export default {
  mixins: [profileMixin],
  data() {
    return {
      headers: [
        { text: 'Ingredient', value: 'Ingredient', sortable: false },
        { text: 'Sub Ingredient', value: 'Sub Ingredient', sortable: false }
      ],
      permits: []
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      loading: 'loading',
      subIngredientList: 'subIngredientList'
    })
  },
  mounted() {},
  methods: {
    saveSubIngredients() {},
    toList(list) {
      return list.toString().replace(/,/g, ', ')
    }
  }
}
</script>
